import { AxiosResponse } from "axios";

import api from "~/utils/api/api";
import { biomarkerValuesToString } from "~/utils/results/formatBiomarkerData";

interface Indicator {
  id: string;
  display_name: string;
}

interface Result {
  file_link: string;
  created_at: string;
}

export type BiomarkerValues = Record<string, number>;

type Patient = {
  id: string;
  fullname: string;
  document_type: string;
  document_number: string;
  gender: string;
  country: string;
  date_of_birth: string;
};

export interface PendingResult {
  id: string;
  created_at: string;
  file_link: string;
  lab_name: string;
  patient: Patient;
  categories: string[];
}

class ResultAPI {
  getResult = async (id: string, token: string): Promise<AxiosResponse<PendingResult>> => {
    return api.get(`v2/results/${id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  getParsedResults = async (id: string, token: string) => {
    return api.get(`v2/results/${id}/parsed_results/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  getIndicators = async (token: string): Promise<AxiosResponse<Indicator[]>> => {
    return api.get("v2/biomarkers/indicators/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  createResult = async (formData: BiomarkerValues, selectedDate: string, id: string, token: string): Promise<void> => {
    try {
      await api.post(
        `v2/results/create-biomarkers/`,
        { biomarkers: biomarkerValuesToString(formData), taken_at: selectedDate, result_id: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      // You might want to handle success here, e.g., display a success message.
    } catch (error) {
      console.error("Error submitting form:", error);
      throw error;
    }
  };

  listPendingResults = async (token: string): Promise<AxiosResponse<PendingResult[]>> => {
    return api.get("v2/results/pendings/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  listReviewedResults = async (token: string): Promise<AxiosResponse<PendingResult[]>> => {
    return api.get("v2/results/reviewed/", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
}

export async function deleteResult(resultId: string) {
  return await api.delete(`v2/results/${resultId}/`);
}

const resultAPI = new ResultAPI();
export default resultAPI;
